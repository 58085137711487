/* eslint no-alert: "off" */
/* eslint max-len: "off" */
/* eslint jsx-a11y/anchor-is-valid: "off" */

// import node_modules
import { Helmet } from 'react-helmet';
import React from 'react';

// import React components
import Layout from '../components/Layout';

// declare React component
const Datenschutz = () => {
  return (
    <Layout
      canonical="https://www.echogramm.de/datenschutz/"
      description="Datenschutzerklärung des Echogramms. Wir beachten den Datenschutz. Mehr erfahren."
      image="https://www.echogramm.de/img/echogramm-bubbles.jpg"
      lang="de"
      title="Echogramm | Datenschutzerklärung"
    >
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <section id="datenschutz" className="section white-bg">
        <div className="container">
          <div className="columns is-mobile is-centered is-multiline has-padding-top-5 has-padding-bottom-5">
            <div className="column is-full-mobile is-three-quarters-tablet is-one-third-desktop is-one-fifths-widescreen is-one-fifths-fullhd">
              <aside className="privacy-menu">
                <p>
                  <a
                    href="/datenschutz/#einfuehrung"
                    onClick={(event) => {
                      event.preventDefault();
                      document.getElementById('einfuehrung').scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    Einführung
                  </a>
                </p>
                <p>
                  <a
                    href="/datenschutz/#sicherheitsmassnahmen"
                    onClick={(event) => {
                      event.preventDefault();
                      document
                        .getElementById('sicherheitsmassnahmen')
                        .scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    Sicherheitsmaßnahmen
                  </a>
                </p>
                <p>
                  <a
                    href="/datenschutz/#datenweitergabe"
                    onClick={(event) => {
                      event.preventDefault();
                      document
                        .getElementById('datenweitergabe')
                        .scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    Datenweitergabe
                  </a>
                </p>
                <p>
                  <a
                    href="/datenschutz/#leistungserbringung"
                    onClick={(event) => {
                      event.preventDefault();
                      document
                        .getElementById('leistungserbringung')
                        .scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    Leistungserbringung
                  </a>
                </p>
                <p>
                  <a
                    href="/datenschutz/#kommunikation"
                    onClick={(event) => {
                      event.preventDefault();
                      document
                        .getElementById('kommunikation')
                        .scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    Kommunikation
                  </a>
                </p>
                <p>
                  <a
                    href="/datenschutz/#datenerhebung"
                    onClick={(event) => {
                      event.preventDefault();
                      document
                        .getElementById('datenerhebung')
                        .scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    Datenerhebung
                  </a>
                </p>
                <p>
                  <a
                    href="/datenschutz/#verwendete-dienste"
                    onClick={(event) => {
                      event.preventDefault();
                      document
                        .getElementById('verwendete-dienste')
                        .scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    Verwendete Dienste
                  </a>
                </p>
              </aside>
            </div>

            <div className="column is-full-mobile is-three-quarters-tablet is-two-thirds-desktop is-three-fifths-widescreen is-three-fifths-fullhd content">
              {/* Intro */}
              <h1 className="title is-size-2">Datenschutz&shy;erklärung</h1>

              <p>
                mii ventures GmbH
                <br />
                Fährbrücker Straße 8
                <br />
                97262 Hausen bei Würzburg
                <br />
                Deutschland
                <br />
                <br />
                Handelsregister, Amtsgericht Würzburg, HRB 12645
                <br />
                Geschäftsführer: Michael Schmitt
                <br />
                <br />
                Telefonnummer: +49 9367 4133897
                <br />
                E-Mail-Adresse: hello@mii.ventures
              </p>

              {/* Einführung */}

              <h2 id="einfuehrung" className="title is-size-3 has-padding-top-1">
                Einführung
              </h2>

              <h3 className="title is-size-5 has-padding-top-1">
                Grundsätzliche Angaben zur Datenverarbeitung und Rechtsgrundlagen
              </h3>
              <p>
                Diese Datenschutzerklärung klärt Dich über die Art, den Umfang und Zweck der
                Verarbeitung von personenbezogenen Daten innerhalb unseres Onlineangebotes und der
                mit ihm verbundenen Webseiten, Funktionen und Inhalte (nachfolgend gemeinsam
                bezeichnet als „Onlineangebot“ oder „Website“) auf. Die Datenschutzerklärung gilt
                unabhängig von den verwendeten Domains, Systemen, Plattformen und Geräten (z.B.
                Desktop oder Mobile) auf denen das Onlineangebot ausgeführt wird.
              </p>
              <p>
                Die verwendeten Begrifflichkeiten, wie z.B. „personenbezogene Daten“ oder deren
                „Verarbeitung“ verweisen wir auf die Definitionen im Art. 4 der
                Datenschutzgrundverordnung (DSGVO).
              </p>
              <p>
                Zu den im Rahmen dieses Onlineangebotes verarbeiteten personenbezogenen Daten der
                Nutzer gehören Bestandsdaten (z.B. Namen und Adressen von Kunden), Vertragsdaten
                (z.B. in Anspruch genommene Leistungen, Namen von Sachbearbeitern,
                Zahlungsinformationen), Nutzungsdaten (z.B. die besuchten Webseiten unseres
                Onlineangebotes, Interesse an unseren Produkten) und Inhaltsdaten (z.B. Eingaben im
                Kontaktformular).
              </p>
              <p>
                Der Begriff „Nutzer“ umfasst alle Kategorien von der Datenverarbeitung betroffener
                Personen. Zu ihnen gehören unsere Geschäftspartner, Kunden, Interessenten und
                sonstige Besucher unseres Onlineangebotes. Die verwendeten Begrifflichkeiten, wie
                z.B. „Nutzer“ sind geschlechtsneutral zu verstehen.
              </p>
              <p>
                Wir verarbeiten personenbezogene Daten der Nutzer nur unter Einhaltung der
                einschlägigen Datenschutzbestimmungen. Das bedeutet, die Daten der Nutzer werden nur
                bei Vorliegen einer gesetzlichen Erlaubnis verarbeitet. D.h., insbesondere wenn die
                Datenverarbeitung zur Erbringung unserer vertraglichen Leistungen (z.B. Bearbeitung
                von Aufträgen) sowie Online-Services erforderlich, bzw. gesetzlich vorgeschrieben
                ist, eine Einwilligung der Nutzer vorliegt, als auch aufgrund unserer berechtigten
                Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb
                und Sicherheit unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO,
                insbesondere bei der Reichweitenmessung, Erstellung von Profilen zu Werbe- und
                Marketingzwecken sowie Erhebung von Zugriffsdaten und Einsatz der Dienste von
                Drittanbietern.
              </p>
              <p>
                Wir weisen darauf hin, dass die Rechtsgrundlage der Einwilligungen Art. 6 Abs. 1
                lit. a. und Art. 7 DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung
                unserer Leistungen und Durchführung vertraglicher Maßnahmen Art. 6 Abs. 1 lit. b.
                DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen
                Verpflichtungen Art. 6 Abs. 1 lit. c. DSGVO, und die Rechtsgrundlage für die
                Verarbeitung zur Wahrung unserer berechtigten Interessen Art. 6 Abs. 1 lit. f. DSGVO
                ist.
              </p>

              <h3 className="title is-size-5 has-padding-top-1">Rechte der Nutzer</h3>
              <p>
                Nutzer haben das Recht, auf Antrag unentgeltlich Auskunft zu erhalten über die
                personenbezogenen Daten, die von uns über sie gespeichert wurden.
              </p>
              <p>
                Zusätzlich haben die Nutzer das Recht auf Berichtigung unrichtiger Daten,
                Einschränkung der Verarbeitung und Löschung ihrer personenbezogenen Daten, sofern
                zutreffend, ihre Rechte auf Datenportabilität geltend zu machen und im Fall der
                Annahme einer unrechtmäßigen Datenverarbeitung, eine Beschwerde bei der zuständigen
                Aufsichtsbehörde einzureichen.
              </p>
              <p>
                Ebenso können Nutzer Einwilligungen, grundsätzlich mit Auswirkung für die Zukunft,
                widerrufen.
              </p>

              <h3 className="title is-size-5 has-padding-top-1">Löschung von Daten</h3>
              <p>
                Die bei uns gespeicherten Daten werden gelöscht, sobald sie für ihre Zweckbestimmung
                nicht mehr erforderlich sind und der Löschung keine gesetzlichen
                Aufbewahrungspflichten entgegenstehen. Sofern die Daten der Nutzer nicht gelöscht
                werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird
                deren Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und nicht für
                andere Zwecke verarbeitet. Das gilt z.B. für Daten der Nutzer, die aus handels- oder
                steuerrechtlichen Gründen aufbewahrt werden müssen.
              </p>
              <p>
                Nach gesetzlichen Vorgaben erfolgt die Aufbewahrung für 6 Jahre gemäß § 257 Abs. 1
                HGB (Handelsbücher, Inventare, Eröffnungsbilanzen, Jahresabschlüsse, Handelsbriefe,
                Buchungsbelege, etc.) sowie für 10 Jahre gemäß § 147 Abs. 1 AO (Bücher,
                Aufzeichnungen, Lageberichte, Buchungsbelege, Handels- und Geschäftsbriefe, Für
                Besteuerung relevante Unterlagen, etc.).
              </p>

              <h3 className="title is-size-5 has-padding-top-1">Widerspruchsrecht</h3>
              <p>
                Nutzer können der künftigen Verarbeitung ihrer personenbezogenen Daten entsprechend
                den gesetzlichen Vorgaben jederzeit widersprechen. Der Widerspruch kann insbesondere
                gegen die Verarbeitung für Zwecke der Direktwerbung erfolgen.
              </p>

              <h3 className="title is-size-5 has-padding-top-1">
                Änderungen der Datenschutzerklärung
              </h3>
              <p>
                Wir behalten uns vor, die Datenschutzerklärung zu ändern, um sie an geänderte
                Rechtslagen, oder bei Änderungen des Dienstes sowie der Datenverarbeitung
                anzupassen. Dies gilt jedoch nur im Hinblick auf Erklärungen zur Datenverarbeitung.
                Sofern Einwilligungen der Nutzer erforderlich sind oder Bestandteile der
                Datenschutzerklärung Regelungen des Vertragsverhältnisses mit den Nutzern enthalten,
                erfolgen die Änderungen nur mit Zustimmung der Nutzer.
              </p>

              <p>
                Die Nutzer werden gebeten sich regelmäßig über den Inhalt der Datenschutzerklärung
                zu informieren.
              </p>

              {/* Sicherheitsmaßnahmen */}

              <h2 id="sicherheitsmassnahmen" className="title is-size-3 has-padding-top-1">
                Sicherheitsmaßnahmen
              </h2>

              <p>
                Wir treffen organisatorische, vertragliche und technische Sicherheitsmaßnahmen
                entsprechend dem Stand der Technik, um sicherzustellen, dass die Vorschriften der
                Datenschutzgesetze eingehalten werden und um damit die durch uns verarbeiteten Daten
                gegen zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder gegen den
                Zugriff unberechtigter Personen zu schützen.
              </p>
              <p>
                Zu den Sicherheitsmaßnahmen gehört insbesondere die verschlüsselte Übertragung von
                Daten zwischen Deinem Browser und unserem Server.
              </p>

              {/* Datenweitergabe */}

              <h2 id="datenweitergabe" className="title is-size-3 has-padding-top-1">
                Datenweitergabe
              </h2>

              <h3 className="title is-size-5 has-padding-top-1">
                Weitergabe von Daten an Dritte und Drittanbieter
              </h3>
              <p>
                Eine Weitergabe von Daten an Dritte erfolgt nur im Rahmen der gesetzlichen Vorgaben.
                Wir geben die Daten der Nutzer an Dritte nur dann weiter, wenn dies z.B. auf
                Grundlage des Art. 6 Abs. 1 lit. b) DSGVO für Vertragszwecke erforderlich ist oder
                auf Grundlage berechtigter Interessen gem. Art. 6 Abs. 1 lit. f. DSGVO an
                wirtschaftlichem und effektivem Betrieb unseres Geschäftsbetriebes.
              </p>
              <p>
                Sofern wir Subunternehmer einsetzen, um unsere Leistungen bereitzustellen, ergreifen
                wir geeignete rechtliche Vorkehrungen sowie entsprechende technische und
                organisatorische Maßnahmen, um für den Schutz der personenbezogenen Daten gemäß den
                einschlägigen gesetzlichen Vorschriften zu sorgen.
              </p>
              <p>
                Sofern im Rahmen dieser Datenschutzerklärung Inhalte, Werkzeuge oder sonstige Mittel
                von anderen Anbietern (nachfolgend gemeinsam bezeichnet als „Drittanbieter“)
                eingesetzt werden und deren genannter Sitz sich in einem Drittland befindet, ist
                davon auszugehen, dass ein Datentransfer in die Sitzstaaten der Drittanbieter
                stattfindet. Als Drittstaaten sind Länder zu verstehen, in denen die DSGVO kein
                unmittelbar geltendes Recht ist, d.h. grundsätzlich Länder außerhalb der EU, bzw.
                des Europäischen Wirtschaftsraums. Die Übermittlung von Daten in Drittstaaten
                erfolgt entweder, wenn ein angemessenes Datenschutzniveau, eine Einwilligung der
                Nutzer oder sonst eine gesetzliche Erlaubnis vorliegt.
              </p>

              {/* Leistungserbringung */}

              <h2 id="leistungserbringung" className="title is-size-3 has-padding-top-1">
                Leistungserbringung
              </h2>

              <h3 className="title is-size-5 has-padding-top-1">
                Erbringung vertraglicher Leistungen
              </h3>
              <p>
                Wir verarbeiten Bestandsdaten (z.B. Namen und Adressen sowie Kontaktdaten von
                Nutzern), Vertragsdaten (z.B., in Anspruch genommene Leistungen, Namen von
                Kontaktpersonen, Zahlungsinformationen) zwecks Erfüllung unserer vertraglichen
                Verpflichtungen und Serviceleistungen gem. Art. 6 Abs. 1 lit b. DSGVO.
              </p>
              <p>
                Nutzer können optional ein Nutzerkonto anlegen, indem sie insbesondere ihre
                Bestellungen einsehen können. Im Rahmen der Registrierung, werden die erforderlichen
                Pflichtangaben den Nutzern mitgeteilt. Die Nutzerkonten sind nicht öffentlich und
                können von Suchmaschinen nicht indexiert werden. Wenn Nutzer ihr Nutzerkonto
                gekündigt haben, werden deren Daten im Hinblick auf das Nutzerkonto gelöscht,
                vorbehaltlich deren Aufbewahrung ist aus handels- oder steuerrechtlichen Gründen
                entspr. Art. 6 Abs. 1 lit. c DSGVO notwendig. Es obliegt den Nutzern, ihre Daten bei
                erfolgter Kündigung vor dem Vertragsende zu sichern. Wir sind berechtigt, sämtliche
                während der Vertragsdauer gespeicherten Daten des Nutzers unwiederbringlich zu
                löschen.
              </p>
              <p>
                Im Rahmen der Registrierung und erneuter Anmeldungen sowie Inanspruchnahme unserer
                Onlinedienste, speichern wird die IP-Adresse und den Zeitpunkt der jeweiligen
                Nutzerhandlung. Die Speicherung erfolgt auf Grundlage unserer berechtigten
                Interessen, als auch der Nutzer an Schutz vor Missbrauch und sonstiger unbefugter
                Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, außer
                sie ist zur Verfolgung unserer Ansprüche erforderlich oder es besteht hierzu besteht
                eine gesetzliche Verpflichtung gem. Art. 6 Abs. 1 lit. c DSGVO
              </p>
              <p>
                Wir verarbeiten Nutzungsdaten (z.B., die besuchten Webseiten unseres
                Onlineangebotes, Interesse an unseren Produkten) und Inhaltsdaten (z.B., Eingaben im
                Kontaktformular oder Nutzerprofil) für Werbezwecke in einem Nutzerprofil, um den
                Nutzer z.B. Produkthinweise ausgehend von ihren bisher in Anspruch genommenen
                Leistungen einzublenden.
              </p>

              {/* Kommunikation */}

              <h2 id="kommunikation" className="title is-size-3 has-padding-top-1">
                Kommunikation
              </h2>

              <h3 className="title is-size-5 has-padding-top-1">Kontaktaufnahme</h3>
              <p>
                Bei der Kontaktaufnahme mit uns (per Kontaktformular oder E-Mail) werden die Angaben
                des Nutzers zur Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs.
                1 lit. b) DSGVO verarbeitet.
              </p>
              <p>
                Die Angaben der Nutzer können in unserem Customer-Relationship-Management System
                (”CRM System”) oder vergleichbarer Anfragenorganisation gespeichert werden.
              </p>

              <h3 className="title is-size-5 has-padding-top-1">Kommentare und Beiträge</h3>
              <p>
                Wenn Nutzer Kommentare oder sonstige Beiträge hinterlassen, werden ihre IP-Adressen
                auf Grundlage unserer berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f.
                DSGVO für 7 Tage gespeichert.
              </p>
              <p>
                Das erfolgt zu unserer Sicherheit, falls jemand in Kommentaren und Beiträgen
                widerrechtliche Inhalte hinterlässt (Beleidigungen, verbotene politische Propaganda,
                etc.). In diesem Fall können wir selbst für den Kommentar oder Beitrag belangt
                werden und sind daher an der Identität des Verfassers interessiert.
              </p>
              <p>
                Die Angaben der Nutzer können in unserem Kommentar-System oder vergleichbarer
                Anfragenorganisation gespeichert werden.
              </p>

              <h3 className="title is-size-5 has-padding-top-1">Newsletter</h3>
              <p>
                Mit den nachfolgenden Hinweisen klären wir Dich über die Inhalte unseres Newsletters
                sowie das Anmelde-, Versand- und das statistische Auswertungsverfahren sowie Deine
                Widerspruchsrechte auf. Indem Du unseren Newsletter abonnierst, erklärst Du Dich mit
                dem Empfang und den beschriebenen Verfahren einverstanden.
              </p>
              <p>
                Inhalt des Newsletters: Wir versenden Newsletter, E-Mails und weitere elektronische
                Benachrichtigungen mit werblichen Informationen (nachfolgend „Newsletter“) nur mit
                der Einwilligung der Empfänger oder einer gesetzlichen Erlaubnis. Sofern im Rahmen
                einer Anmeldung zum Newsletter dessen Inhalte konkret umschrieben werden, sind sie
                für die Einwilligung der Nutzer maßgeblich. Im Übrigen enthalten unsere Newsletter
                Informationen zu unseren Produkten, Angeboten, Aktionen und unserem Unternehmen.
              </p>
              <p>
                Double-Opt-In und Protokollierung: Die Anmeldung zu unserem Newsletter erfolgt in
                einem sog. Double-Opt-In-Verfahren. D.h. Du erhälst nach der Anmeldung eine E-Mail,
                in der Du um die Bestätigung Deiner Anmeldung gebeten wirst. Diese Bestätigung ist
                notwendig, damit sich niemand mit fremden E-Mailadressen anmelden kann. Die
                Anmeldungen zum Newsletter werden protokolliert, um den Anmeldeprozess entsprechend
                den rechtlichen Anforderungen nachweisen zu können. Hierzu gehört die Speicherung
                des Anmelde- und des Bestätigungszeitpunkts, als auch der IP-Adresse. Ebenso werden
                die Änderungen Deiner bei dem Versanddienstleister gespeicherten Daten
                protokolliert.
              </p>
              <p>
                Versanddienstleister: Der Versand der Newsletter erfolgt mittels „MailChimp“, einer
                Newsletterversandplattform des US-Anbieters Rocket Science Group, LLC, 675 Ponce De
                Leon Ave NE #5000, Atlanta, GA 30308, USA. The Rocket Science Group LLC d/b/a
                MailChimp ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch
                eine Garantie, das europäisches Datenschutzniveau einzuhalten. Weitere Informationen
                findest Du weiter unten im Kapitel Verwendete Dienste.
              </p>
              <p>
                Des Weiteren kann der Versanddienstleister nach eigenen Informationen diese Daten in
                pseudonymer Form, d.h. ohne Zuordnung zu einem Nutzer, zur Optimierung oder
                Verbesserung der eigenen Services nutzen, z.B. zur technischen Optimierung des
                Versandes und der Darstellung der Newsletter oder für statistische Zwecke, um zu
                bestimmen aus welchen Ländern die Empfänger kommen, verwenden. Der
                Versanddienstleister nutzt die Daten unserer Newsletterempfänger jedoch nicht, um
                diese selbst anzuschreiben oder an Dritte weiterzugeben.
              </p>
              <p>
                Anmeldedaten: Um sich für den Newsletter anzumelden, reicht es aus, wenn Du Deine
                E-Mailadresse angiebst.
              </p>
              <p>
                Statistische Erhebung und Analysen - Die Newsletter enthalten einen sog.
                „web-beacon“, d.h. eine pixelgroße Datei, die beim Öffnen des Newsletters von dem
                Server des Versanddienstleisters abgerufen wird. Im Rahmen dieses Abrufs werden
                zunächst technische Informationen, wie Informationen zum Browser und Deinem System,
                als auch Deine IP-Adresse und Zeitpunkt des Abrufs erhoben. Diese Informationen
                werden zur technischen Verbesserung der Services anhand der technischen Daten oder
                der Zielgruppen und ihres Leseverhaltens anhand derer Abruforte (die mit Hilfe der
                IP-Adresse bestimmbar sind) oder der Zugriffszeiten genutzt. Zu den statistischen
                Erhebungen gehört ebenfalls die Feststellung, ob die Newsletter geöffnet werden,
                wann sie geöffnet werden und welche Links geklickt werden. Diese Informationen
                können aus technischen Gründen zwar den einzelnen Newsletterempfängern zugeordnet
                werden. Es ist jedoch weder unser Bestreben, noch das des Versanddienstleisters,
                einzelne Nutzer zu beobachten. Die Auswertungen dienen uns viel mehr dazu, die
                Lesegewohnheiten unserer Nutzer zu erkennen und unsere Inhalte auf sie anzupassen
                oder unterschiedliche Inhalte entsprechend den Interessen unserer Nutzer zu
                versenden.
              </p>
              <p>
                Der Einsatz des Versanddienstleisters, Durchführung der statistischen Erhebungen und
                Analysen sowie Protokollierung des Anmeldeverfahrens, erfolgen auf Grundlage unserer
                berechtigten Interessen gem. Art. 6 Abs. 1 lit. f DSGVO. Unser Interesse richtet
                sich auf den Einsatz eines nutzerfreundlichen sowie sicheren Newslettersystems, das
                sowohl unseren geschäftlichen Interessen dient, als auch den Erwartungen der Nutzer
                entspricht.
              </p>
              <p>
                Kündigung/Widerruf - Du kannst den Empfang unseres Newsletters jederzeit kündigen,
                d.h. Deine Einwilligungen widerrufen. Damit erlöschen gleichzeitig Deine
                Einwilligungen in dessen Versand durch den Versanddienstleister und die
                statistischen Analysen. Ein getrennter Widerruf des Versandes durch den
                Versanddienstleister oder die statistische Auswertung ist leider nicht möglich.
                Einen Link zur Kündigung des Newsletters findest Du am Ende eines jeden Newsletters.
                Wenn die Nutzer sich nur zum Newsletter angemeldet und diese Anmeldung gekündigt
                haben, werden ihre personenbezogenen Daten gelöscht.
              </p>
              <p>
                Rechtsgrundlagen Datenschutzgrundverordnung - Entsprechend den Vorgaben der ab dem
                25. Mai 2018 geltenden Datenschutzgrundverordnung (DSGVO) informieren wir Dich, dass
                die Einwilligungen in den Versand der E-Mailadressen auf Grundlage der Art. 6 Abs. 1
                lit. a, 7 DSGVO sowie § 7 Abs. 2 Nr. 3, bzw. Abs. 3 UWG erfolgt. Der Einsatz des
                Versanddienstleisters MailChimp, Durchführung der statistischen Erhebungen und
                Analysen sowie Protokollierung des Anmeldeverfahrens, erfolgen auf Grundlage unserer
                berechtigten Interessen gem. Art. 6 Abs. 1 lit. f DSGVO. Unser Interesse richtet
                sich auf den Einsatz eines nutzerfreundlichen sowie sicheren Newslettersystems, das
                sowohl unseren geschäftlichen Interessen dient, als auch den Erwartungen der Nutzer
                entspricht. Wir weisen Dich ferner darauf hin, dass Dich der künftigen Verarbeitung
                Deiner personenbezogenen Daten entsprechend den gesetzlichen Vorgaben gem. Art. 21
                DSGVO jederzeit widersprechen können. Der Widerspruch kann insbesondere gegen die
                Verarbeitung für Zwecke der Direktwerbung erfolgen.
              </p>

              {/* Datenerhebung */}

              <h2 id="datenerhebung" className="title is-size-3 has-padding-top-1">
                Datenerhebung
              </h2>

              <h3 className="title is-size-5 has-padding-top-1">
                Erhebung von Zugriffsdaten und Logfiles
              </h3>
              <p>
                Wir erheben auf Grundlage unserer berechtigten Interessen im Sinne des Art. 6 Abs. 1
                lit. f. DSGVO Daten über jeden Zugriff auf den Server, auf dem sich dieser Dienst
                befindet (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der
                abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge,
                Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des
                Nutzers, Referrer URL (die zuvor besuchte Seite), IP-Adresse und der anfragende
                Provider.
              </p>
              <p>
                Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung von
                Missbrauchs- oder Betrugshandlungen) für die Dauer von maximal sieben Tagen
                gespeichert und danach gelöscht. Daten, deren weitere Aufbewahrung zu Beweiszwecken
                erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der
                Löschung ausgenommen.
              </p>

              <h3 className="title is-size-5 has-padding-top-1">Cookies & Reichweitenmessung</h3>
              <p>
                Cookies sind Informationen, die von unserem Webserver oder Webservern Dritter an die
                Web-Browser der Nutzer übertragen und dort für einen späteren Abruf gespeichert
                werden. Bei Cookies kann es sich um kleine Dateien oder sonstige Arten der
                Informationsspeicherung handeln.
              </p>
              <p>
                Wir verwenden keine Cookies von Drittanbietern auf dieser Website, auch nicht zur
                pseudonymer Reichweitenmessung. Wir setzen lediglich einen Cookie zur Speicherung
                deiner Sprachauswahl, sofern diese von deinen allgemeinen Browser-Einstellungen
                abweicht.
              </p>
              <p>
                Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden,
                werden sie gebeten die entsprechende Option in den Systemeinstellungen ihres
                Browsers zu deaktivieren. Gespeicherte Cookies können in den Systemeinstellungen des
                Browsers gelöscht werden. Der Ausschluss von Cookies kann zu
                Funktionseinschränkungen dieses Onlineangebotes führen.
              </p>

              {/* Verwendete Dienste */}

              <h2 id="verwendete-dienste" className="title is-size-3 has-padding-top-1">
                Verwendete Dienste
              </h2>

              <h3 className="title is-size-5 has-padding-top-1">
                Einbindung von Diensten und Inhalten Dritter
              </h3>
              <p>
                Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer berechtigten
                Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb
                unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder
                Serviceangebote von Drittanbietern ein, um deren Inhalte und Services, wie z.B.
                Videos oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet als
                “Inhalte”). Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die
                IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse die Inhalte nicht an
                deren Browser senden könnten. Die IP-Adresse ist damit für die Darstellung dieser
                Inhalte erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren
                jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden.
                Drittanbieter können ferner so genannte Pixel-Tags (unsichtbare Grafiken, auch als
                “Web Beacons” bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die
                ”Pixel-Tags” können Informationen, wie der Besucherverkehr auf den Seiten dieser
                Website ausgewertet werden. Die pseudonymen Informationen können ferner auf dem
                Gerät der Nutzer gespeichert werden und unter anderem technische Informationen zum
                Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere Angaben
                zur Nutzung unseres Onlineangebotes enthalten, als auch mit solchen Informationen
                aus anderen Quellen verbunden werden können.
              </p>
              <p>
                Die nachfolgende Darstellung bietet eine Übersicht von Drittanbietern sowie ihrer
                Inhalte, nebst Links zu deren Datenschutzerklärungen, welche weitere Hinweise zur
                Verarbeitung von Daten und, z.T. bereits hier genannt, Widerspruchsmöglichkeiten
                (sog. Opt-Out) enthalten.
              </p>

              {/* Vercel */}

              <h3 className="title is-size-5 has-padding-top-1">Vercel</h3>
              <p>
                Zur Bereitstellung unserer Website und Web-Apps verwenden wir die Cloud-Plattform
                Vercel, die von Vercel Inc. 340 S Lemon Ave #4133 Walnut, CA 91789, im Rahmen eines
                Datenverarbeitungsabkommens (Art. 28 GDPR) bereitgestellt wird. Jeder Besuch unserer
                Website wird über Vercel abgewickelt oder ausgeliefert, das Informationen,
                einschließlich IP-Adressen, Systemkonfigurationsinformationen und andere
                Informationen über den Datenverkehr zu und von unserer Website verarbeitet, um den
                Service zu betreiben, zu erhalten und zu verbessern. Diese Daten können helfen, neue
                Bedrohungen zu erkennen, böswillige Dritte zu identifizieren und einen robusteren
                Sicherheitsschutz zu bieten.
              </p>
              <p>
                Die Verarbeitung dieser Daten ist technisch notwendig, um die Nutzung unserer
                Website zu ermöglichen (Art. 6 (1) lit. b GDPR).
              </p>

              <div className="content">
                <ul>
                  <li>
                    <a
                      href="https://www.privacyshield.gov/participant?id=a2zt0000000TTIbAAO&status=Active"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Shield Vercel
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://vercel.com/legal/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Datenschutzerklärung Vercel
                    </a>
                  </li>
                </ul>
              </div>

              <br />

              <p>Stand: 18. Juli 2022</p>
            </div>
          </div>
        </div>
      </section>

      <section id="demo" className="section bg-demo">
        <div className="container">
          <div className="columns is-mobile is-centered">
            <div className="column is-full-mobile is-four-fifths-tablet is-three-quarters-desktop is-two-thirds-widescreen is-three-fifths-full-hd has-text-centered">
              <h2 className="is-size-2">Demo anfordern</h2>
              <p className="is-size-5">
                Das Echogramm ist eine Social News App, die dir alle wichtigsten News deiner Branche
                auf einen Blick liefert. In einem persönlichen Gespräch zeigen wir dir die
                Funktionen und Möglichkeiten des Echogramms für dein Unternehmen. Du möchtest
                frischen Wind in deiner und der Filterblase deines Teams? Fordere jetzt
                unverbindlich eine persönliche Produktvorführung an.
              </p>
              <p>
                <a
                  href="mailto:echogramm@mii.ventures?subject=Produktdemo Echogramm"
                  className="button is-echogramm"
                >
                  Produktdemo anfordern
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Datenschutz;
